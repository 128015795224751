import { Box, CircularProgress, styled, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFaq from "../../hooks/useFaq";
import { clearDatasetDetils } from "../../redux/features/datasets/datasetsSlice";
import { clearModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { DELETE_FAQ_MODAL } from "../../utils/constants";
import ErrorButton from "../buttons/ErrorButton";
import SecondaryOutlineButton from "../buttons/SecondaryOutLineButton";
import ModalTemplate from "./ModalTemplate";

export const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	background: "#fff",
	borderRadius: "5px",
	boxSizing: "border-box",
	flex: 1,
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		justifyContent: "center",
	},
}));
const DeleteFaqModal = () => {
	const dispatch = useDispatch();
	const { deleteFaq } = useFaq();
	const { modalName } = useSelector((state: RootState) => state.modal);
	const [isSubmitting, setIsSubmitting] = useState<Boolean>(false);
	const { faq } = useSelector((state: RootState) => state.faq);
	const handleClose = () => {
		dispatch(clearDatasetDetils());
		dispatch(clearModalName());
	};
	let heading = "";
	let description = "";
	if (faq) {
		heading = "Delete Question";
		description = "Are you sure you want to delete this question?";
	}
	const handleDelete = async () => {
		setIsSubmitting(true);
		deleteFaq().then((r: unknown) => {
			// const data = r as unknown as any;
			setIsSubmitting(false);
			handleClose();
		});
	};
	return (
		<ModalTemplate openModal={modalName === DELETE_FAQ_MODAL}>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
			>
				<Wrapper>
					<Box
						display={"flex"}
						flexDirection="column"
						justifyContent={"center"}
						mt={2}
					>
						<Typography
							variant="h6"
							align="center"
							sx={{ paddingBottom: "10px" }}
						>
							{heading}
						</Typography>
						<Typography variant="body1" align="center">
							{description}
						</Typography>
					</Box>
					<Box
						display={"flex"}
						flexDirection="row"
						justifyContent={"center"}
						margin="auto"
						gap={2}
						pt={4}
					>
						<ErrorButton
							text={"Yes, Delete"}
							disabled={isSubmitting ? true : false}
							onClick={handleDelete}
							icon={
								isSubmitting ? (
									<CircularProgress
										size="0.6rem"
										color="inherit"
									/>
								) : null
							}
						/>

						<SecondaryOutlineButton
							text={"Cancel"}
							disabled={isSubmitting ? true : false}
							onClick={handleClose}
						/>
					</Box>
				</Wrapper>
			</Box>
		</ModalTemplate>
	);
};
export default DeleteFaqModal;
