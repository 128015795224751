import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../contexts/Authcontext";
import { TagUpdateRequest, TagUpdateRequestTypeEnum } from "../openapi";
import { BulkTagCreateRequest } from "../openapi/models/bulk-tag-create-request";
import { TagCreateRequest } from "../openapi/models/tag-create-request";
import { clearModalName } from "../redux/features/modal/modalSlice";
import {
  clearTagDetils,
  editTag,
  storeAllContentTypes,
  storeAllTagsStrings,
  storeAllCategoriesStrings,
  storeAllKeywordsStrings,
  storeCategories,
  storeCategoriesPaginationDetails,
  storeDeleteStatus,
  storeKeywords,
  storeKeywordsPaginationDetails,
  storePaginationDetails,
  storeTags,
  storeTypes,
  storeTypesPaginationDetails,
} from "../redux/features/tags/tagsSlice";
import { RootState } from "../redux/store";
import { getOffset } from "../utils";
import { getTagService } from "../utils/apiHelpers";
import { PAGE_LIMIT } from "../utils/constants";

const useTag = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = useAuthContext();
  const { tag } = useSelector((state: RootState) => state.tag);

  const getTagList = async (page?: number, type?: TagUpdateRequestTypeEnum) => {
    if (accessToken) {
      setIsLoading(true);
      if (page) {
        let offset = getOffset(page);

        try {
          const service = await getTagService(accessToken);
          const response = await service.getTags(type, PAGE_LIMIT, offset);
          if (response.status === 200 || response.status === 201) {
            if (type === TagUpdateRequestTypeEnum.Category) {
              dispatch(storeCategoriesPaginationDetails(response.data));
              dispatch(storeCategories(response.data.tags));
            } else if (type === TagUpdateRequestTypeEnum.Keyword) {
              dispatch(storeKeywordsPaginationDetails(response.data));
              dispatch(storeKeywords(response.data.tags));
            } else if (type === TagUpdateRequestTypeEnum.Type) {
              dispatch(storeTypesPaginationDetails(response.data));
              dispatch(storeTypes(response.data.tags));
            } else {
              dispatch(storePaginationDetails(response.data));
              dispatch(storeTags(response.data.tags));
            }
            setIsLoading(false);
          } else {
            setIsLoading(false);
            console.log("error", response.data);
          }
        } catch (error: any) {
          setIsLoading(false);
          enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
            variant: "error",
          });
          console.log("error", error);
          // if (error.response.status === 500) {
          //   navigate("*");
          // }
        }
      } else {
        try {
          const service = await getTagService(accessToken);
          const response = await service.getTags(type, "1000", "0");
          if (response.status === 200 || response.status === 201) {
            if (type === TagUpdateRequestTypeEnum.Category) {
              dispatch(storeCategories(response.data.tags));
              dispatch(storeCategoriesPaginationDetails(response.data));
            } else if (type === TagUpdateRequestTypeEnum.Keyword) {
              dispatch(storeKeywords(response.data.tags));
              dispatch(storeKeywordsPaginationDetails(response.data));
            } else if (type === TagUpdateRequestTypeEnum.Type) {
              dispatch(storeTypesPaginationDetails(response.data));
              dispatch(storeTypes(response.data.tags));
            }
            else {
              dispatch(storeTags(response.data.tags));
              dispatch(storePaginationDetails(response.data));
            }
            setIsLoading(false);
          } else {
            setIsLoading(false);
            console.log("error", response.data);
          }
        } catch (error: any) {
          setIsLoading(false);
          enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
            variant: "error",
          });
          console.log("error", error);
          // if (error.response.status === 500) {
          //   navigate("*");
          // }
        }
      }
    }
  };
  const getAllTagsofType = async (type: TagUpdateRequestTypeEnum) => {
    if (accessToken) {
      setIsLoading(true);
      try {
        const service = await getTagService(accessToken);
        const response = await service.getTags(type, "1000", "0");
        if (response.status === 200 || response.status === 201) {
          if (type === TagUpdateRequestTypeEnum.Type) {
            const allContentTypes = response.data.tags.map((tag) => tag.title);
            dispatch(storeAllContentTypes(allContentTypes));
          }
          if (type === TagUpdateRequestTypeEnum.Tag) {
            const allTagsStrings = response.data.tags.map((tag) => tag.title);
            dispatch(storeAllTagsStrings(allTagsStrings));
          }
          if (type === TagUpdateRequestTypeEnum.Category) {
            const allCategoriesStrings = response.data.tags.map((tag) => tag.title);
            dispatch(storeAllCategoriesStrings(allCategoriesStrings));
          }
          if (type === TagUpdateRequestTypeEnum.Keyword) {
            const allKeywordsStrings = response.data.tags.map((tag) => tag.title);
            dispatch(storeAllKeywordsStrings(allKeywordsStrings));
          }

          setIsLoading(false);
          return response.data.tags;
        } else {
          setIsLoading(false);
          console.log("error", response.data);
          return [];
        }
      } catch (error: any) {
        setIsLoading(false);
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        console.log("error", error);
        return [];
      }
    }
  }
  const createTag = async (values: TagCreateRequest) => {
    if (accessToken && values) {
      setCreateLoading(true);
      // const createRequest: TagCreateRequest = {
      //   title: value,
      // };
      // if (type) {
      //   createRequest.type = type;
      // }
      // if (vectroClass) {
      //   createRequest.vectorClass = vectroClass;
      // }
      try {
        const service = await getTagService(accessToken);
        const response = await service.createtag(values);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Keyword created successfully", {
            variant: "success",
          });
          setCreateLoading(false);
          //dispatch(createTags(true));
          getTagList(1, TagUpdateRequestTypeEnum.Category);
          getTagList(1, TagUpdateRequestTypeEnum.Keyword);
          getTagList(1, TagUpdateRequestTypeEnum.Tag);
          getTagList(1, TagUpdateRequestTypeEnum.Type);
          return response.data;
        } else {
          setCreateLoading(false);
          // console.log("create Failed", response.data);
          enqueueSnackbar("Faied to create ", {
            variant: "error",
          });
        }
      } catch (error: any) {
        // enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
        //   variant: "error",
        // });

        enqueueSnackbar(error?.response?.data?.message?.[0] ?? error?.response?.data?.message ?? error.toString() ?? "Failed to create", {
          variant: "error",
        });

        // enqueueSnackbar("Faied to create ", {
        //   variant: "error",
        // });
        setCreateLoading(false);
        console.log("catch error create", error);
        return false
      }
    }
  };
  const updateTag = async (values: TagUpdateRequest) => {
    if (accessToken && tag) {
      setIsLoading(true);
      // console.log("updateTag values", values);
      try {
        const service = await getTagService(accessToken);
        const response = await service.updateTag(values);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Keyword updated successfully", {
            variant: "success",
          });
          setIsLoading(false);
          dispatch(editTag(values));
          dispatch(clearModalName());
          dispatch(clearTagDetils());

          getTagList(1, TagUpdateRequestTypeEnum.Category);
          getTagList(1, TagUpdateRequestTypeEnum.Keyword);
          getTagList(1, TagUpdateRequestTypeEnum.Tag);
          getTagList(1, TagUpdateRequestTypeEnum.Type);
          return response.data;
        } else {
          setIsLoading(false);
          console.log("error", response.data);
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        setIsLoading(false);
        console.log("error", error);
      }
    } else {
      enqueueSnackbar("Failed to update", {
        variant: "error",
      });
      setIsLoading(false);
    }
  };
  const deleteTag = async () => {
    if (accessToken && tag) {
      setIsLoading(true);
      try {
        const productService = await getTagService(accessToken);
        const response = await productService.deletetag(tag.id);
        if (response.status === 200) {
          enqueueSnackbar("Keyword deleted successfully", {
            variant: "success",
          });
          getTagList(1, tag.type as TagUpdateRequestTypeEnum);
          setIsLoading(false);
          dispatch(storeDeleteStatus(true));
          dispatch(clearModalName());

          getTagList(1, TagUpdateRequestTypeEnum.Category);
          getTagList(1, TagUpdateRequestTypeEnum.Keyword);
          getTagList(1, TagUpdateRequestTypeEnum.Tag);
          getTagList(1, TagUpdateRequestTypeEnum.Type);
        } else {
          setIsLoading(false);
          console.log("error", response.data);
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        setIsLoading(false);
        console.log("error", error);
      }
    }
  };

  const createBulkTags = async (values: any) => {
    if (accessToken && values) {
      const tags = values.keywords.split(",").map((tag: string) => tag.trim());
      const createRequest: BulkTagCreateRequest = {
        tags: tags,
      };
      try {
        const service = await getTagService(accessToken);
        const response = await service.createBulkTags(createRequest);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Keywords created successfully", {
            variant: "success",
          });
          // getTagList(1);
          getTagList(1, TagUpdateRequestTypeEnum.Keyword);
          getTagList(1, TagUpdateRequestTypeEnum.Category);
          getTagList(1, TagUpdateRequestTypeEnum.Tag);
          getTagList(1, TagUpdateRequestTypeEnum.Type);
          return response.data;
        } else {
          console.log("error", response.data);
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        console.log("error", error);
      }
    }
  };

  return {
    getTagList,
    createTag,
    isLoading,
    createLoading,
    updateTag,
    deleteTag,
    createBulkTags,
    getAllTagsofType
  };
};
export default useTag;
