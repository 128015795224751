import CloseIcon from "@mui/icons-material/Close";
import { Box, Slide, styled, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCategoryDetils } from "../../redux/features/categories/categoriesSlice";
import { clearHistoryDetils } from "../../redux/features/chatHistory/chatHistorySlice";
import { clearDatasetDetils } from "../../redux/features/datasets/datasetsSlice";
import { clearFaqDetils } from "../../redux/features/faq/faqSlice";
import { clearModalName } from "../../redux/features/modal/modalSlice";
import { clearTagDetils } from "../../redux/features/tags/tagsSlice";
import { RootState } from "../../redux/store";
export enum ModalDimension {
	large,
	medium,
	small,
}
interface IModalTemplate {
	title?: string;
	subTitle?: string;
	children?: React.ReactNode;
	footer?: React.ReactNode;
	openModal?: boolean;
	disableModalClose?: boolean;
	headerAlign?: string;
	dimension?: ModalDimension;
}

const ModalTemplate: FC<IModalTemplate> = ({
	title,
	subTitle,
	children,
	footer,
	openModal,
	disableModalClose = false,
	headerAlign,
	dimension,
}) => {
	const Header = styled(Box)(({ theme }) => ({
		position: "relative",
		// display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: headerAlign ? headerAlign : "center",
		padding: "20px 30px",
		borderBottom: "1px solid #e6e6e6",
	}));
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
	const { modalName } = useSelector((state: RootState) => state.modal);

	const handleClose = () => {
		if (disableModalClose) return;
		setOpen(false);
		dispatch(clearModalName());
		dispatch(clearCategoryDetils());
		dispatch(clearTagDetils());
		dispatch(clearDatasetDetils());
		dispatch(clearHistoryDetils());
		dispatch(clearFaqDetils());
	};

	useEffect(() => {
		if (!modalName) {
			setOpen(false);
			dispatch(clearModalName());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalName]);

	useEffect(() => {
		if (openModal) {
			setOpen(true);
		}
	}, [openModal]);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				transition: "0.3s all ease-in-out",
			}}
		>
			<Slide direction="up" in={open} mountOnEnter unmountOnExit>
				<ContentWrapper
					sx={{
						maxWidth:
							dimension === ModalDimension.large
								? "1000px"
								: dimension === ModalDimension.medium
								? "700px"
								: dimension === ModalDimension.small
								? "600px"
								: "500px",
					}}
				>
					{!disableModalClose ? (
						<IconContainer onClick={handleClose} aria-label="Close">
							<CloseIcon fontSize="small" />
						</IconContainer>
					) : null}
					{title && (
						<Header>
							{title && <Title variant="h5">{title}</Title>}
							{subTitle && <SubTitle>{subTitle}</SubTitle>}
						</Header>
					)}

					{children && <Content>{children}</Content>}
					{footer && <Footer>{footer}</Footer>}
				</ContentWrapper>
			</Slide>
		</Modal>
	);
};
export default ModalTemplate;

const ContentWrapper = styled(Box)(({ theme }) => ({
	position: "absolute",
	width: "90%",
	background: "#fff",
	borderRadius: "14px",
	outline: "none",
	display: "flex",
	flexDirection: "column",
	maxHeight: "90%",
	height: "auto",
	maxWidth: "500px",
	boxShadow: "0px 5px 10px #535353",
	overflow: "hidden",
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "20px",
	fontWeight: "500",
	lineHeight: "30px",
	textAlign: "left",
	transition: "0.3s all ease-in-out",
}));

const SubTitle = styled(Typography)(({ theme }) => ({
	fontWeight: 400,
	fontSize: "14px",
	lineHeight: "21px",
	textAlign: "center",
	transition: "0.3s all ease-in-out",
}));
const Content = styled(Box)(({ theme }) => ({
	padding: "30px",
	overflow: "auto",
	flex: 1,
	transition: "0.3s all ease-in-out",
	[theme.breakpoints.only("xs")]: {
		padding: "15px",
	},
}));
const Footer = styled(Box)(() => ({
	borderTop: "1px solid #e6e6e6",
}));
const IconContainer = styled(Box)(() => ({
	position: "absolute",
	right: "10px",
	top: "15px",
	cursor: "pointer",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "50%",
	padding: "2px",
	zIndex: "99999999",
	"&:hover": {
		backgroundColor: "#E5E5E5",
	},
}));
