/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import {
	Autocomplete,
	Box,
	Checkbox,
	CircularProgress,
	createFilterOptions,
	FormControlLabel,
	Stack,
	styled,
	TextField,
	Typography,
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useAuthContext } from "../../contexts/Authcontext";
import usePreview from "../../hooks/usePreview";
import { clearDatasetDetils } from "../../redux/features/datasets/datasetsSlice";
import { clearModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { PREVIEW_MODAL } from "../../utils/constants";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryOutlineButton from "../buttons/SecondaryOutLineButton";
import { FormField } from "../forms/FormField";
import { FormTextArea } from "../forms/FormTextArea";
import ModalTemplate, { ModalDimension } from "./ModalTemplate";
import { PreviewsRequest, PreviewsUpdateRequest } from "../../openapi";
import useTag from "../../hooks/useTag";

const PreviewModal = () => {
	const dispatch = useDispatch();
	const { accessToken } = useAuthContext();
	const { getAllTagsofType } = useTag();

	const { createContent, updatePreview } = usePreview();
	const { modalName } = useSelector((state: RootState) => state.modal);
	const { preview } = useSelector((state: RootState) => state.preview);

	const { allTagsStrings, allContentTypes } = useSelector(
		(state: RootState) => state.tag
	);
	const filter = createFilterOptions<any>();
	const [checked, setChecked] = useState(false);

	const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	useEffect(() => {
		if (!allContentTypes) {
			getAllTagsofType("Type");
		}
		if (!allTagsStrings) {
			getAllTagsofType("Tag");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let initialValues: PreviewsRequest | PreviewsUpdateRequest = {
		description: "",
		title: "",
		favicon: "",
		imageUrl: "",
		url: "",
		site_name: "",
		searchToolType: "Shome",
		tags: [""],
		contentTypes: [""],
		updateAllFavicons: checked,
	};
	if (preview) {
		initialValues = {
			id: preview.id,
			description: preview.description || "",
			title: preview.title || "",
			favicon: preview.favicon || "",
			imageUrl: preview.imageUrl || "",
			url: preview.url || "",
			site_name: preview.site_name || "",
			searchToolType: (preview.searchToolType as any) || "Shome",
			tags: preview.tags || [],
			contentTypes: preview.contentTypes || [],
			updateAllFavicons: checked,
		};
	}
	const validationSchema = Yup.object().shape({
		title: Yup.string().min(3).required().label("Title"),
		description: Yup.string().optional().label("Content"),
		favicon: Yup.string().optional().label("Favicon"),
		url: Yup.string().min(3).required().label("Url"),
		site_name: Yup.string().min(2).optional().label("Site Name"),
		tags: Yup.array().of(Yup.string()).optional().label("Tags"),
		contentTypes: Yup.array()
			.of(Yup.string())
			.optional()
			.label("Content Types"),
		updateAllFavicons: Yup.boolean()
			.optional()
			.label("Update All Favicons"),
	});
	const handleClose = () => {
		dispatch(clearModalName());
		dispatch(clearDatasetDetils());
	};
	const onSubmit = (values: any, actions: any) => {
		addPreview(values, actions);
	};
	const addPreview = async (values: any, actions: any) => {
		// console.log("values", values);
		if (!accessToken) return;
		let tagIds: any[] = [];
		actions.setSubmitting(true);
		if (values.tags && values.tags.length) {
			values.tags.map((item: string) => {
				tagIds.push(item);
			});
		}
		let contentTypeIds: any[] = [];
		if (values.contentTypes && values.contentTypes.length) {
			values.contentTypes.map((item: string) => {
				contentTypeIds.push(item);
			});
		}
		let request: any = {
			description: values.description,
			title: values.title,
			favicon: values.favicon,
			imageUrl: values.imageUrl,
			url: values.url,
			site_name: values.site_name,
			searchToolType: values.searchToolType,
			tags: values.tags,
			contentTypes: values.contentTypes,
			updateAllFavicons: checked,
		};
		if (preview) {
			request.id = preview.id;
			updatePreview(request).then((r: unknown) => {
				const data = r as unknown as any;
				if (data) {
					if (checked) setChecked(false);
					actions.setSubmitting(false);
					handleClose();
				}
			});
		} else {
			createContent(request).then((r: unknown) => {
				const data = r as unknown as any;
				if (data) {
					if (checked) setChecked(false);
					actions.setSubmitting(false);
					handleClose();
				}
			});
		}
	};
	return (
		<ModalTemplate
			openModal={modalName === PREVIEW_MODAL}
			title={preview ? "Edit Preview" : "Add Preview"}
			dimension={ModalDimension.large}
		>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="left"
				justifyContent="center"
			>
				<Wrapper>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{(formik) => {
							return (
								<Form style={{ width: "100%" }}>
									<Box mb={4}>
										<FormField
											name={"title"}
											label={"Title"}
											placeholder="Enter title"
										/>
									</Box>
									<Box mb={4}>
										<FormField
											name={"favicon"}
											label={"Favicon"}
											placeholder="Enter favicon"
										/>
										<FormControlLabel
											control={
												<Checkbox
													checked={checked}
													onChange={handleChecked}
													aria-label="controlled"
													name="updateAllFavicons"
												/>
											}
											label="Update All Favicons"
										/>
									</Box>

									<Box mb={4}>
										<FormTextArea
											name={"description"}
											label={"Description"}
											placeholder="Enter Description"
										/>
									</Box>
									<Box mb={4}>
										<FormField
											name={"url"}
											label={"Url"}
											placeholder="Enter url"
										/>
									</Box>
									<Box mb={4}>
										<FormField
											name={"site_name"}
											label={"Site Name"}
											placeholder="Enter site name"
										/>
									</Box>
									<Box mb={4}>
										<Label>Tags</Label>
										<Autocomplete
											multiple
											size="medium"
											fullWidth
											value={formik.values.tags}
											filterSelectedOptions
											id="multiple-limit-tags"
											options={allTagsStrings ?? []}
											loading={!allTagsStrings}
											disableClearable
											getOptionLabel={(option: any) =>
												option
											}
											isOptionEqualToValue={(
												option,
												value
											) => option === value}
											forcePopupIcon={false}
											onChange={(
												event: React.SyntheticEvent,
												newValue: any
											) => {
												formik.setFieldValue(
													"tags",
													newValue
												);
											}}
											filterOptions={(
												options,
												params
											) => {
												const filtered = filter(
													options,
													params
												);
												const { inputValue } = params;
												const isExisting =
													options.every(
														(option) =>
															inputValue
																.toLocaleLowerCase()
																.replace(
																	/\s+/g,
																	" "
																)
																.trim() ===
															option.toLowerCase()
													);
												let isPresent: boolean = false;
												if (
													allTagsStrings &&
													allTagsStrings.length
												) {
													allTagsStrings.map(
														(item: any) => {
															if (
																inputValue
																	.toLocaleLowerCase()
																	.replace(
																		/\s+/g,
																		" "
																	)
																	.trim() ===
																item.toLowerCase()
															) {
																isPresent =
																	true;
															}
														}
													);
												}
												if (
													inputValue !== "" &&
													!isExisting &&
													!isPresent
												) {
													filtered.unshift(
														inputValue
													);
												} else if (
													!filtered.length &&
													inputValue !== ""
												) {
													filtered.push(inputValue);
												}
												return filtered;
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													name="tags"
													size="medium"
													placeholder={
														!formik.values.tags
															.length
															? "Select or create tags"
															: ""
													}
													value={formik.values.tags}
													error={
														formik.touched.tags &&
														Boolean(
															formik.errors.tags
														)
													}
													sx={{
														"& fieldset": {
															borderRadius: "8px",
														},
													}}
												/>
											)}
										/>
										<ErrorMessage name={"tags"}>
											{(msg) => (
												<ErrorMessageWapper>
													{msg}
												</ErrorMessageWapper>
											)}
										</ErrorMessage>
									</Box>

									<Box mb={4}>
										<Label>Content Types</Label>

										<Autocomplete
											multiple
											size="medium"
											fullWidth
											value={formik.values.contentTypes}
											filterSelectedOptions
											id="multiple-limit-contentTypes"
											options={allContentTypes ?? []}
											loading={!allContentTypes}
											getOptionLabel={(option: any) =>
												option
											}
											isOptionEqualToValue={(
												option,
												value
											) => option === value}
											forcePopupIcon={false}
											onChange={(
												event: React.SyntheticEvent,
												newValue: any
											) => {
												formik.setFieldValue(
													"contentTypes",
													newValue
												);
											}}
											filterOptions={(
												options,
												params
											) => {
												const filtered = filter(
													options,
													params
												);
												const { inputValue } = params;
												const isExisting =
													options.every(
														(option) =>
															inputValue
																.toLocaleLowerCase()
																.replace(
																	/\s+/g,
																	" "
																)
																.trim() ===
															option.toLowerCase()
													);
												let isPresent: boolean = false;
												if (
													allContentTypes &&
													allContentTypes.length
												) {
													allContentTypes.map(
														(item: any) => {
															if (
																inputValue
																	.toLocaleLowerCase()
																	.replace(
																		/\s+/g,
																		" "
																	)
																	.trim() ===
																item.toLowerCase()
															) {
																isPresent =
																	true;
															}
														}
													);
												}
												if (
													inputValue !== "" &&
													!isExisting &&
													!isPresent
												) {
													filtered.unshift(
														inputValue
													);
												} else if (
													!filtered.length &&
													inputValue !== ""
												) {
													filtered.push(inputValue);
												}
												return filtered;
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													name="contentTypes"
													size="medium"
													placeholder={
														!(
															formik.values
																.contentTypes ??
															[]
														).length
															? "Select or create contentTypes"
															: ""
													}
													value={
														formik.values
															.contentTypes
													}
													error={
														formik.touched
															.contentTypes &&
														Boolean(
															formik.errors
																.contentTypes
														)
													}
													sx={{
														"& fieldset": {
															borderRadius: "8px",
														},
													}}
												/>
											)}
										/>
										<ErrorMessage name={"contentTypes"}>
											{(msg) => (
												<ErrorMessageWapper>
													{msg}
												</ErrorMessageWapper>
											)}
										</ErrorMessage>
									</Box>

									<Stack direction="row" spacing={2}>
										<PrimaryButton
											text={preview ? "Save" : "Add New"}
											style={{ width: "100%" }}
											disabled={formik.isSubmitting}
											type="submit"
											icon={
												formik.isSubmitting ? (
													<CircularProgress
														size="1rem"
														color="inherit"
													/>
												) : null
											}
										/>
										<SecondaryOutlineButton
											style={{ width: "100%" }}
											text="Close"
											onClick={handleClose}
										/>
									</Stack>
								</Form>
							);
						}}
					</Formik>
				</Wrapper>
			</Box>
		</ModalTemplate>
	);
};
export default PreviewModal;

export const Wrapper = styled(Box)(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	background: "#fff",
	borderRadius: "5px",
	boxSizing: "border-box",
	flex: 1,
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		justifyContent: "flex-start",
	},
}));

export const Label = styled(Typography)(() => ({
	fontSize: "16px",
	fontWeight: "500",
	color: "#2C3659",
	lineHeight: "21px",
	marginBottom: "5px",
}));

const ErrorMessageWapper = styled(Box)(() => ({
	color: "#F2323F",
	fontSize: "12px",
	fontWeight: 400,
	marginTop: "5px",
}));
