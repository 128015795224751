/* eslint-disable react-hooks/exhaustive-deps */
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import { Box, Typography, useMediaQuery } from "@mui/material";
import {
	GridActionsCellItem,
	GridColDef,
	GridRowParams,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmptyListAnimation } from "../../animations/EmptyListAnimation";
import useTag from "../../hooks/useTag";
import { saveModalName } from "../../redux/features/modal/modalSlice";
import {
	storeDeleteStatus,
	storeTagDetails,
} from "../../redux/features/tags/tagsSlice";
import { RootState } from "../../redux/store";
import { theme } from "../../theme";
import { DELETE_TAG_MODAL, TAG_MODAL } from "../../utils/constants";
import DeleteTagModal from "../modals/DeleteTagModal";
import { TableTemplate } from "../table/TableTemplate";
import { TagDataGrid } from "./TagDataGrid";
import TagHeaderSection from "./TagHeaderSection";
import PrimaryButton from "../buttons/PrimaryButton";
import TagModal from "../modals/TagModal";

const TagsList = () => {
	const dispatch = useDispatch();
	const { getTagList, isLoading } = useTag();
	const isXs = useMediaQuery(theme.breakpoints.only("xs"));
	const isSm = useMediaQuery(theme.breakpoints.up("sm"));
	const {
		tags,
		categories,
		keywords,
		categoryPagination,
		keywordPagination,
		pagination,
		deleted,
		types,
		typesPagination,
	} = useSelector((state: RootState) => state.tag);
	//const [searchValue, setSearchValue] = useState<string | null>(null);
	const [page, setPage] = useState(1);
	const [keywordPage, setKeywordPage] = useState(1);
	const [categoryPage, setCategoryPage] = useState(1);
	const [typePage, setTypePage] = useState(1);
	const handlePageChange = (event: any, newPage: any) => {
		setPage(newPage);
	};
	const handleKeywordPageChange = (event: any, newPage: any) => {
		setKeywordPage(newPage);
	};
	const handleCategoryPageChange = (event: any, newPage: any) => {
		setCategoryPage(newPage);
	};
	const handleTypePageChange = (event: any, newPage: any) => {
		setTypePage(newPage);
	};
	const handleEditClick = (params: GridRowParams) => {
		dispatch(storeTagDetails(params.row));
		dispatch(saveModalName(TAG_MODAL));
	};
	const handleDelete = (params: GridRowParams) => {
		dispatch(storeTagDetails(params.row));
		dispatch(saveModalName(DELETE_TAG_MODAL));
	};

	const categoryColumns: GridColDef[] = [
		{
			field: "title",
			headerName: "Name",
			sortable: false,
			flex: isSm ? 0.7 : undefined,
			width: isXs ? 180 : undefined,
		},
		{
			field: "type",
			headerName: "Type",
			sortable: false,
			flex: isSm ? 0.7 : undefined,
			width: isXs ? 180 : undefined,
		},
		{
			field: "vectorClass",
			headerName: "Vector Class",
			sortable: false,
			flex: isSm ? 0.7 : undefined,
			width: isXs ? 180 : undefined,
		},
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 80,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<CreateOutlinedIcon fontSize="small" />}
					label="Edit"
					onClick={() => handleEditClick(params)}
				/>,
				<GridActionsCellItem
					icon={<DeleteOutlineOutlinedIcon fontSize="small" />}
					label="Delete"
					onClick={() => handleDelete(params)}
				/>,
			],
		},
	];

	const columns: GridColDef[] = [
		{
			field: "title",
			headerName: "Name",
			sortable: false,
			flex: isSm ? 0.7 : undefined,
			width: isXs ? 180 : undefined,
		},
		{
			field: "type",
			headerName: "Type",
			sortable: false,
			flex: isSm ? 0.7 : undefined,
			width: isXs ? 180 : undefined,
		},
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 80,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<CreateOutlinedIcon fontSize="small" />}
					label="Edit"
					onClick={() => handleEditClick(params)}
				/>,
				<GridActionsCellItem
					icon={<DeleteOutlineOutlinedIcon fontSize="small" />}
					label="Delete"
					onClick={() => handleDelete(params)}
				/>,
			],
		},
	];
	// let searchedListers: any[] = [];
	// const handleSearch = async (value: string) => {
	//   setSearchValue(value);
	// };
	useEffect(() => {
		getTagList(page, "Tag");
	}, [page]);
	useEffect(() => {
		getTagList(categoryPage, "Category");
	}, [categoryPage]);
	useEffect(() => {
		getTagList(keywordPage, "Keyword");
	}, [keywordPage]);
	useEffect(() => {
		getTagList(typePage, "Type");
	}, [typePage]);
	useEffect(() => {
		if (deleted) {
			setPage(1);
			dispatch(storeDeleteStatus(false));
		}
	}, [deleted]);
	return (
		<Box pt={3} gap={4}>
			<Box
				sx={{
					marginBottom: 5,
				}}
			>
				<Box
					sx={{
						display: "flex",
						gap: 2,
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h6" ml={2}>
						Categories, Tags, Content Types, and Keywords
					</Typography>
					<PrimaryButton
						text="Add New"
						startIcon={<AddIcon />}
						onClick={() => dispatch(saveModalName(TAG_MODAL))}
					/>
				</Box>
				<TagModal />
			</Box>

			<Box
				sx={{
					marginBottom: 2,
				}}
			>
				<TableTemplate
					search={
						<Typography variant="h6" ml={2}>
							Categories
						</Typography>
					}
					list={categories}
					dataGrid={
						<TagDataGrid
							paginationData={categoryPagination}
							list={categories}
							columns={categoryColumns}
							page={categoryPage}
							handlePageChange={handleCategoryPageChange}
						/>
					}
					buttonGroups={null}
					emptyView={
						<EmptyListAnimation title="No category found. Start typing a category and click the 'Add New' button to create one. " />
					}
					loading={isLoading}
				/>
			</Box>

			<Box
				sx={{
					marginBottom: 2,
				}}
			>
				<TableTemplate
					search={
						<Typography variant="h6" ml={2}>
							Tags
						</Typography>
					}
					list={tags}
					dataGrid={
						<TagDataGrid
							paginationData={pagination}
							list={tags}
							columns={columns}
							page={page}
							handlePageChange={handlePageChange}
						/>
					}
					buttonGroups={null}
					emptyView={
						<EmptyListAnimation title="No Tags found. Start typing a Tags and click the 'Add New' button to create one. " />
					}
					loading={isLoading}
				/>
			</Box>
			<Box sx={{ marginBottom: 2 }}>
				<TableTemplate
					search={
						<Typography variant="h6" ml={2}>
							Content Types
						</Typography>
					}
					list={types}
					dataGrid={
						<TagDataGrid
							paginationData={typesPagination}
							list={types}
							columns={columns}
							page={typePage}
							handlePageChange={handleTypePageChange}
						/>
					}
					buttonGroups={null}
					emptyView={
						<EmptyListAnimation title="No Types found. Start typing a Types and click the 'Add New' button to create one. " />
					}
					loading={isLoading}
				/>
			</Box>
			<TableTemplate
				search={<TagHeaderSection />}
				list={keywords}
				dataGrid={
					<TagDataGrid
						paginationData={keywordPagination}
						list={keywords}
						columns={columns}
						page={keywordPage}
						handlePageChange={handleKeywordPageChange}
					/>
				}
				buttonGroups={null}
				emptyView={
					<EmptyListAnimation title="No keyword found. Start typing a keyword and click the 'Add New' button to create one. " />
				}
				loading={isLoading}
			/>
			<DeleteTagModal />
		</Box>
	);
};
export default TagsList;
