import { Box, CircularProgress, styled, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../../contexts/Authcontext";
import useCategory from "../../hooks/useCategory";
import { clearCategoryDetils } from "../../redux/features/categories/categoriesSlice";
import { clearModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { DELETE_CATEGORY_MODAL } from "../../utils/constants";
import ErrorButton from "../buttons/ErrorButton";
import SecondaryOutlineButton from "../buttons/SecondaryOutLineButton";
import ModalTemplate from "./ModalTemplate";
export const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	background: "#fff",
	borderRadius: "5px",
	boxSizing: "border-box",
	flex: 1,
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		justifyContent: "center",
	},
}));
const DeleteCategoryModal = () => {
	const dispatch = useDispatch();
	const { accessToken } = useAuthContext();
	const { deleteCategory } = useCategory();
	const { modalName } = useSelector((state: RootState) => state.modal);
	const [isSubmitting, setIsSubmitting] = useState<Boolean>(false);
	const { category } = useSelector((state: RootState) => state.category);

	const handleClose = () => {
		dispatch(clearCategoryDetils());
		dispatch(clearModalName());
	};
	let description = "";
	if (category) {
		description = "Are you sure you want to delete this category?";
	}
	const handleDeleteProduct = async () => {
		if (!accessToken) return;
		if (category) {
			setIsSubmitting(true);
			deleteCategory(category.id);
			setIsSubmitting(false);
		}
	};
	return (
		<ModalTemplate
			openModal={modalName === DELETE_CATEGORY_MODAL}
			title="Delete Category"
		>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
			>
				<Wrapper>
					<Box
						display={"flex"}
						flexDirection="column"
						justifyContent={"center"}
						mt={2}
					>
						<Typography variant="body1" align="center">
							{description}
						</Typography>
					</Box>
					<Box
						display={"flex"}
						flexDirection="row"
						justifyContent={"center"}
						margin="auto"
						gap={2}
						pt={4}
					>
						<ErrorButton
							text={"Yes, Delete"}
							disabled={isSubmitting ? true : false}
							onClick={handleDeleteProduct}
							icon={
								isSubmitting ? (
									<CircularProgress
										size="0.6rem"
										color="inherit"
									/>
								) : null
							}
						/>

						<SecondaryOutlineButton
							text={"Cancel"}
							disabled={isSubmitting ? true : false}
							onClick={handleClose}
						/>
					</Box>
				</Wrapper>
			</Box>
		</ModalTemplate>
	);
};
export default DeleteCategoryModal;
