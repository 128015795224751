import React from "react";
import {
	Autocomplete,
	Box,
	CircularProgress,
	createFilterOptions,
	IconButton,
	Stack,
	styled,
	TextField,
	Typography,
} from "@mui/material";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useAuthContext } from "../../contexts/Authcontext";
import useDataset from "../../hooks/useDataset";
import { DatasetCreateRequest } from "../../openapi";
import { clearDatasetDetils } from "../../redux/features/datasets/datasetsSlice";
import { clearModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { CONTENT_MODAL } from "../../utils/constants";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryOutlineButton from "../buttons/SecondaryOutLineButton";
import { FormField } from "../forms/FormField";
import { FormTextArea } from "../forms/FormTextArea";
import ModalTemplate, { ModalDimension } from "./ModalTemplate";
import useTag from "../../hooks/useTag";

const ContentModal = () => {
	const dispatch = useDispatch();
	const { accessToken } = useAuthContext();
	const { getAllTagsofType } = useTag();
	const { createContent, updateDataset } = useDataset();
	const { modalName } = useSelector((state: RootState) => state.modal);
	const { dataset } = useSelector((state: RootState) => state.dataset);
	const filter = createFilterOptions<any>();
	const {
		allContentTypes,
		allTagsStrings,
		allCategoriesStrings,
		allKeywordsStrings,
	} = useSelector((state: RootState) => state.tag);
	let initialValues: DatasetCreateRequest = {
		title: "",
		resourceQuestions: dataset?.resourceQuestions || [""],
		bodyText: "",
		keywords: [],
		link: "",
		summary: "",
		tags: [],
		contentTypes: [],
		category: [],
	};
	if (dataset) {
		initialValues = {
			title: dataset.title || "",
			resourceQuestions: dataset.resourceQuestions || [""],
			bodyText: dataset.bodyText || "",
			link: dataset.link,
			summary: dataset.summary || "",
			keywords: dataset.keywords || [],
			tags: dataset.tags || [],
			contentTypes: dataset.contentTypes || [],
			category: dataset.category || [],
		};
	}
	const validationSchema = Yup.object().shape({
		title: Yup.string().min(3).required().label("Title"),
		resourceQuestions: Yup.array()
			.of(Yup.string())
			.optional()
			.label("Questions"),
		bodyText: Yup.string().min(3).required().label("Content"),
		summary: Yup.string().min(3).required().label("Summery"),
		keywords: Yup.array().of(Yup.string()).optional().label("Keywords"),
		tags: Yup.array().of(Yup.string()).optional().label("Tags"),
		contentTypes: Yup.array()
			.of(Yup.string())
			.optional()
			.label("Content Types"),
		category: Yup.array().of(Yup.string()).optional().label("Category"),
		link: Yup.string().min(3).required().label("Link"),
	});
	const handleClose = () => {
		dispatch(clearModalName());
		dispatch(clearDatasetDetils());
	};
	const onSubmit = (values: any, actions: any) => {
		addDataset(values, actions);
	};

	const addDataset = async (values: any, actions: any) => {
		if (!accessToken) return;
		actions.setSubmitting(true);
		let request = {
			title: values.title,
			bodyText: values.bodyText,
			link: values.link,
			summary: values.summary,
			tags: values.tags,
			contentTypes: values.contentTypes,
			keywords: values.keywords,
			category: values.category,
			resourceQuestions: values.resourceQuestions,
		};
		if (dataset) {
			updateDataset(request)
				.then((r: unknown) => {
					const data = r as unknown as any;
					if (data) {
						actions.setSubmitting(false);
						handleClose();
					} else {
						actions.setSubmitting(false);
					}
				})
				.catch((error) => {
					// console.log("updateDataset error", error);
					actions.setSubmitting(false);
					handleClose();
				});
		} else {
			createContent(request)
				.then((r: unknown) => {
					const data = r as unknown as any;
					if (data) {
						actions.setSubmitting(false);
						handleClose();
					} else {
						actions.setSubmitting(false);
					}
				})
				.catch((error) => {
					// console.log("createContent error", error);
					actions.setSubmitting(false);
					handleClose();
				});
		}
	};

	useEffect(() => {
		if (!allContentTypes) {
			getAllTagsofType("Type");
		}
		if (!allTagsStrings) {
			getAllTagsofType("Tag");
		}
		if (!allCategoriesStrings) {
			getAllTagsofType("Category");
		}
		if (!allKeywordsStrings) {
			getAllTagsofType("Keyword");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ModalTemplate
			openModal={modalName === CONTENT_MODAL}
			title={dataset ? "Edit Content" : "Add Content"}
			dimension={ModalDimension.large}
			disableModalClose={true}
		>
			{dataset && dataset.failedReason && (
				<Typography color="error" mb={4}>
					Failed Reason: {dataset.failedReason}
				</Typography>
			)}
			<Box
				display="flex"
				flexDirection="column"
				alignItems="left"
				justifyContent="center"
			>
				<Wrapper>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{(formik) => {
							return (
								<Form style={{ width: "100%" }}>
									<Box mb={4}>
										<Label>Category</Label>
										{allCategoriesStrings && (
											<Autocomplete
												multiple
												size="medium"
												fullWidth
												value={formik.values.category}
												filterSelectedOptions
												id="multiple-limit-category"
												options={allCategoriesStrings}
												loading={!allCategoriesStrings}
												disableClearable
												getOptionLabel={(option: any) =>
													option
												}
												isOptionEqualToValue={(
													option,
													value
												) => option === value}
												forcePopupIcon={false}
												onChange={(
													event: React.SyntheticEvent,
													newValue: any
												) => {
													formik.setFieldValue(
														"category",
														newValue
													);
												}}
												filterOptions={(
													options,
													params
												) => {
													const filtered = filter(
														options,
														params
													);
													return filtered;
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														name="category"
														size="medium"
														placeholder={
															!(
																formik.values
																	.category ??
																[]
															).length
																? "Select or create category"
																: ""
														}
														value={
															formik.values
																.category
														}
														error={
															formik.touched
																.category &&
															Boolean(
																formik.errors
																	.category
															)
														}
														sx={{
															"& fieldset": {
																borderRadius:
																	"8px",
															},
														}}
													/>
												)}
											/>
										)}
										<ErrorMessage name={"category"}>
											{(msg) => (
												<ErrorMessageWapper>
													{msg}
												</ErrorMessageWapper>
											)}
										</ErrorMessage>
									</Box>
									<Box mb={3}>
										<FormField
											name={"title"}
											label={"Title"}
											placeholder="Enter title"
										/>
									</Box>

									<Box mb={3}>
										<FormField
											name={"link"}
											label={"Link"}
											placeholder="Enter link"
										/>
									</Box>

									<Box mb={4}>
										<Label>Keywords</Label>

										<Autocomplete
											multiple
											size="medium"
											fullWidth
											value={formik.values.keywords}
											filterSelectedOptions
											id="multiple-limit-keywords"
											options={allKeywordsStrings ?? []}
											loading={!allKeywordsStrings}
											disableClearable
											getOptionLabel={(option: any) =>
												option
											}
											isOptionEqualToValue={(
												option,
												value
											) => option === value}
											forcePopupIcon={false}
											onChange={(
												event: React.SyntheticEvent,
												newValue: any
											) => {
												formik.setFieldValue(
													"keywords",
													newValue
												);
											}}
											filterOptions={(
												options,
												params
											) => {
												const filtered = filter(
													options,
													params
												);
												const { inputValue } = params;
												const isExisting =
													options.every(
														(option) =>
															inputValue
																.toLocaleLowerCase()
																.replace(
																	/\s+/g,
																	" "
																)
																.trim() ===
															option.toLowerCase()
													);
												let isPresent: boolean = false;
												if (
													allKeywordsStrings &&
													allKeywordsStrings.length
												) {
													allKeywordsStrings.map(
														// eslint-disable-next-line array-callback-return
														(item: any) => {
															if (
																inputValue
																	.toLocaleLowerCase()
																	.replace(
																		/\s+/g,
																		" "
																	)
																	.trim() ===
																item.toLowerCase()
															) {
																isPresent =
																	true;
															}
														}
													);
												}
												if (
													inputValue !== "" &&
													!isExisting &&
													!isPresent
												) {
													filtered.unshift(
														inputValue
													);
												} else if (
													!filtered.length &&
													inputValue !== ""
												) {
													filtered.push(inputValue);
												}
												return filtered;
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													name="keywords"
													size="medium"
													placeholder={
														!(
															formik.values
																.keywords ?? []
														).length
															? "Select or create keywords"
															: ""
													}
													value={
														formik.values.keywords
													}
													error={
														formik.touched
															.keywords &&
														Boolean(
															formik.errors
																.keywords
														)
													}
													sx={{
														"& fieldset": {
															borderRadius: "8px",
														},
													}}
												/>
											)}
										/>
										<ErrorMessage name={"keywords"}>
											{(msg) => (
												<ErrorMessageWapper>
													{msg}
												</ErrorMessageWapper>
											)}
										</ErrorMessage>
									</Box>

									<Box mb={4}>
										<Label>Tags</Label>
										<Autocomplete
											multiple
											size="medium"
											fullWidth
											value={formik.values.tags}
											filterSelectedOptions
											id="multiple-limit-tags"
											options={allTagsStrings ?? []}
											loading={!allTagsStrings}
											disableClearable
											getOptionLabel={(option: any) =>
												option
											}
											isOptionEqualToValue={(
												option,
												value
											) => option === value}
											forcePopupIcon={false}
											onChange={(
												event: React.SyntheticEvent,
												newValue: any
											) => {
												formik.setFieldValue(
													"tags",
													newValue
												);
											}}
											filterOptions={(
												options,
												params
											) => {
												const filtered = filter(
													options,
													params
												);
												const { inputValue } = params;
												const isExisting =
													options.every(
														(option) =>
															inputValue
																.toLocaleLowerCase()
																.replace(
																	/\s+/g,
																	" "
																)
																.trim() ===
															option.toLowerCase()
													);
												let isPresent: boolean = false;
												if (
													allTagsStrings &&
													allTagsStrings.length
												) {
													allTagsStrings.map(
														// eslint-disable-next-line array-callback-return
														(item: any) => {
															if (
																inputValue
																	.toLocaleLowerCase()
																	.replace(
																		/\s+/g,
																		" "
																	)
																	.trim() ===
																item.toLowerCase()
															) {
																isPresent =
																	true;
															}
														}
													);
												}
												if (
													inputValue !== "" &&
													!isExisting &&
													!isPresent
												) {
													filtered.unshift(
														inputValue
													);
												} else if (
													!filtered.length &&
													inputValue !== ""
												) {
													filtered.push(inputValue);
												}
												return filtered;
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													name="tags"
													size="medium"
													placeholder={
														!(
															formik.values
																.tags ?? []
														).length
															? "Select or create tags"
															: ""
													}
													value={formik.values.tags}
													error={
														formik.touched.tags &&
														Boolean(
															formik.errors.tags
														)
													}
													sx={{
														"& fieldset": {
															borderRadius: "8px",
														},
													}}
												/>
											)}
										/>
										<ErrorMessage name={"tags"}>
											{(msg) => (
												<ErrorMessageWapper>
													{msg}
												</ErrorMessageWapper>
											)}
										</ErrorMessage>
									</Box>

									<Box mb={4}>
										<Label>Content Types</Label>

										<Autocomplete
											multiple
											size="medium"
											fullWidth
											value={formik.values.contentTypes}
											filterSelectedOptions
											id="multiple-limit-contentTypes"
											options={allContentTypes ?? []}
											loading={!allContentTypes}
											disableClearable
											getOptionLabel={(option: any) =>
												option
											}
											isOptionEqualToValue={(
												option,
												value
											) => option === value}
											forcePopupIcon={false}
											onChange={(
												event: React.SyntheticEvent,
												newValue: any
											) => {
												formik.setFieldValue(
													"contentTypes",
													newValue
												);
											}}
											filterOptions={(
												options,
												params
											) => {
												const filtered = filter(
													options,
													params
												);
												const { inputValue } = params;
												const isExisting =
													options.every(
														(option) =>
															inputValue
																.toLocaleLowerCase()
																.replace(
																	/\s+/g,
																	" "
																)
																.trim() ===
															option.toLowerCase()
													);
												let isPresent: boolean = false;
												if (
													allContentTypes &&
													allContentTypes.length
												) {
													allContentTypes.map(
														// eslint-disable-next-line array-callback-return
														(item: any) => {
															if (
																inputValue
																	.toLocaleLowerCase()
																	.replace(
																		/\s+/g,
																		" "
																	)
																	.trim() ===
																item.toLowerCase()
															) {
																isPresent =
																	true;
															}
															return item;
														}
													);
												}
												if (
													inputValue !== "" &&
													!isExisting &&
													!isPresent
												) {
													filtered.unshift(
														inputValue
													);
												} else if (
													!filtered.length &&
													inputValue !== ""
												) {
													filtered.push(inputValue);
												}
												return filtered;
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													name="contentTypes"
													size="medium"
													placeholder={
														!(
															formik.values
																.contentTypes ??
															[]
														).length
															? "Select or create contentTypes"
															: ""
													}
													value={
														formik.values
															.contentTypes
													}
													error={
														formik.touched
															.contentTypes &&
														Boolean(
															formik.errors
																.contentTypes
														)
													}
													sx={{
														"& fieldset": {
															borderRadius: "8px",
														},
													}}
												/>
											)}
										/>
										<ErrorMessage name={"contentTypes"}>
											{(msg) => (
												<ErrorMessageWapper>
													{msg}
												</ErrorMessageWapper>
											)}
										</ErrorMessage>
									</Box>

									<FormTextArea
										name={"bodyText"}
										label={"Content"}
										placeholder="Enter content"
									/>
									{/* {dataset && ( */}
									<FormTextArea
										name={"summary"}
										label={"Summary"}
										placeholder="Enter summary"
									/>
									{/* Dynamic Questions Input */}

									<Box mb={4}>
										<Label>Questions</Label>
										<FieldArray name="resourceQuestions">
											{({ push, remove }) => (
												<div>
													{(
														formik.values
															.resourceQuestions ??
														[]
													).map((_, index) => (
														<Box
															key={index}
															display="flex"
															alignItems="center"
															mb={2}
														>
															<TextField
																name={`resourceQuestions.${index}`}
																label={`Question ${
																	index + 1
																}`}
																variant="outlined"
																fullWidth
																value={
																	formik
																		.values
																		.resourceQuestions &&
																	formik
																		.values
																		.resourceQuestions[
																		index
																	]
																}
																onChange={
																	formik.handleChange
																}
																error={
																	formik
																		.touched
																		.resourceQuestions &&
																	Boolean(
																		formik
																			.errors
																			.resourceQuestions?.[
																			index
																		]
																	)
																}
																helperText={
																	formik
																		.errors
																		.resourceQuestions?.[
																		index
																	]
																}
															/>
															<IconButton
																color="error"
																onClick={() =>
																	remove(
																		index
																	)
																}
																disabled={
																	(
																		formik
																			.values
																			.resourceQuestions ??
																		[]
																	).length ===
																	1
																}
																aria-label="remove question"
															>
																<RemoveCircleOutlineIcon />
															</IconButton>
															{index ===
																(formik.values
																	.resourceQuestions
																	?.length ??
																	0) -
																	1 && (
																<IconButton
																	color="primary"
																	onClick={() =>
																		push("")
																	}
																	aria-label="add question"
																>
																	<AddCircleOutlineIcon />
																</IconButton>
															)}
														</Box>
													))}
												</div>
											)}
										</FieldArray>
									</Box>
									<Stack direction="row" spacing={2}>
										<PrimaryButton
											text={dataset ? "Save" : "Add New"}
											style={{ width: "100%" }}
											disabled={formik.isSubmitting}
											type="submit"
											icon={
												formik.isSubmitting ? (
													<CircularProgress
														size="1rem"
														color="inherit"
													/>
												) : null
											}
										/>
										<SecondaryOutlineButton
											style={{ width: "100%" }}
											text="Close"
											onClick={handleClose}
										/>
									</Stack>
								</Form>
							);
						}}
					</Formik>
				</Wrapper>
			</Box>
		</ModalTemplate>
	);
};
export default ContentModal;

export const Wrapper = styled(Box)(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	background: "#fff",
	borderRadius: "5px",
	boxSizing: "border-box",
	flex: 1,
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		justifyContent: "flex-start",
	},
}));

export const Label = styled(Typography)(() => ({
	fontSize: "16px",
	fontWeight: "500",
	color: "#2C3659",
	lineHeight: "21px",
	marginBottom: "5px",
}));

const ErrorMessageWapper = styled(Box)(() => ({
	color: "#F2323F",
	fontSize: "12px",
	fontWeight: 400,
	marginTop: "5px",
}));
