import {
	Box,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	IconButton,
	Stack,
	styled,
	TextField,
} from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useAuthContext } from "../../contexts/Authcontext";
import useTag from "../../hooks/useTag";
import { clearModalName } from "../../redux/features/modal/modalSlice";
import { clearTagDetils } from "../../redux/features/tags/tagsSlice";
import { RootState } from "../../redux/store";
import { TAG_MODAL } from "../../utils/constants";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryOutlineButton from "../buttons/SecondaryOutLineButton";
import { FormField } from "../forms/FormField";
import ModalTemplate, { ModalDimension } from "./ModalTemplate";
import {
	TagCreateRequest,
	TagUpdateRequest,
	TagUpdateRequestTypeEnum,
} from "../../openapi";
import { Select, Typography } from "@material-ui/core";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
const TagModal = () => {
	const dispatch = useDispatch();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const { createTag, updateTag } = useTag();
	const { accessToken } = useAuthContext();
	const { modalName } = useSelector((state: RootState) => state.modal);
	const { tag } = useSelector((state: RootState) => state.tag);

	const [checked, setChecked] = useState(false);

	const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
		console.log("event.target.checked", event.target.checked);
		setChecked(event.target.checked);
	};
	useEffect(() => {
		if (tag) {
			console.log("tag.rerank", tag.rerank);
			setChecked(tag.rerank);
		}
	}, [tag]);
	let initialValues = {
		title: "",
		type: "Keyword",
		vectorClass: "",
		thresholdShome: "",
		thresholdExa: "",
		rerank: checked,
		similarQuestions: [""],
		similarUrls: [""],
		similarDesc: [""],
		homeQuestions: [""],
		homeUrls: [""],
		homeDesc: [""],
		additionalInfoHome: [],
		additionalInfoSimilar: [],
	};
	if (tag) {
		initialValues = {
			title: tag.title,
			type: tag.type as TagUpdateRequestTypeEnum,
			vectorClass:
				tag.type === TagUpdateRequestTypeEnum.Category
					? tag.vectorClass
					: "",
			thresholdShome: tag.thresholdShome || "",
			thresholdExa: tag.thresholdExa || "",
			rerank: tag.rerank || false,
			similarQuestions: tag?.similarQuestions || [""],
			similarUrls: tag?.similarUrls || [""],
			similarDesc: tag?.similarDesc || [""],
			homeQuestions: tag?.homeQuestions || [""],
			homeUrls: tag?.homeUrls || [""],
			homeDesc: tag?.homeDesc || [""],
			additionalInfoHome:
				(tag?.additionalInfoHome as unknown as []) || [],
			additionalInfoSimilar:
				(tag?.additionalInfoSimilar as unknown as []) || [],
		};
		// if (tag.rerank) {
		// 	setChecked(true);
		// }
	}

	const validationSchema = Yup.object().shape({
		title: Yup.string().min(2).required().label("Keyword"),
		type: Yup.string().required().label("Type"),
		vectorClass: Yup.string()
			.when("type", {
				is: TagUpdateRequestTypeEnum.Category,
				then: (schema) => schema.required("Vector Class is required"),
				otherwise: (schema) => schema.notRequired(),
			})
			.label("Vector Class"),
		thresholdShome: Yup.string()
			.matches(/^\d*\.?\d*$/, "Threshold Shome must be a number")
			.optional()
			.label("Threshold Shome"),
		thresholdExa: Yup.string()
			.matches(/^\d*\.?\d*$/, "Threshold Exa must be a number")
			.optional()
			.label("Threshold Exa"),
		rerank: Yup.boolean().optional().label("Rerank"),
		similarQuestions: Yup.array()
			.of(Yup.string())
			.optional()
			.label("Similar Questions"),
		similarUrls: Yup.array()
			.of(Yup.string())
			.optional()
			.label("Similar URLs"),
		similarDesc: Yup.array()
			.of(Yup.string())
			.optional()
			.label("Similar Descriptions"),
		homeQuestions: Yup.array()
			.of(Yup.string())
			.optional()
			.label("Home Questions"),
		homeUrls: Yup.array().of(Yup.string()).optional().label("Home URLs"),
		homeDesc: Yup.array()
			.of(Yup.string())
			.optional()
			.label("Home Descriptions"),
		additionalInfoHome: Yup.array()
			.of(Yup.object())
			.optional()
			.label("Additional Info Home"),
		additionalInfoSimilar: Yup.array()
			.of(Yup.object())
			.optional()
			.label("Additional Info Similar"),
	});

	const handleClose = () => {
		dispatch(clearModalName());
		dispatch(clearTagDetils());
	};

	const onSubmit = (values: any, actions: any) => {
		const homeQuestionsLength = values?.homeQuestions?.length
			? values.homeQuestions.length
			: 0;
		const homeUrlsLength = values?.homeUrls?.length
			? values.homeUrls.length
			: 0;
		const homeDescLength = values?.homeDesc?.length
			? values.homeDesc.length
			: 0;
		if (
			homeQuestionsLength !== homeUrlsLength ||
			homeQuestionsLength !== homeDescLength ||
			homeUrlsLength !== homeDescLength
		) {
			actions.setFieldError("homeQuestions", "All fields are required");
			actions.setFieldError("homeUrls", "All fields are required");
			actions.setFieldError("homeDesc", "All fields are required");
			// cancel the form submission
			actions.setSubmitting(false);
			return;
		}
		const similarQuestionsLength = values?.similarQuestions?.length
			? values.similarQuestions.length
			: 0;
		const similarUrlsLength = values?.similarUrls?.length
			? values.similarUrls.length
			: 0;
		const similarDescLength = values?.similarDesc?.length
			? values.similarDesc.length
			: 0;
		if (
			similarQuestionsLength !== similarUrlsLength ||
			similarQuestionsLength !== similarDescLength ||
			similarUrlsLength !== similarDescLength
		) {
			actions.setFieldError(
				"similarQuestions",
				"All fields are required"
			);
			actions.setFieldError("similarUrls", "All fields are required");
			actions.setFieldError("similarDesc", "All fields are required");
			// cancel the form submission
			actions.setSubmitting(false);
			return;
		}

		addTag(values, actions);
	};

	const addTag = async (values: any, actions: any) => {
		console.log("values and", values);
		const additionalInfoHome: any[] = [];
		const additionalInfoSimilar: any[] = [];
		if (values.type === TagUpdateRequestTypeEnum.Category) {
			if (values?.homeQuestions?.length) {
				// await values.homeQuestions.map(
				// 	// eslint-disable-next-line array-callback-return
				// 	(question: string, index: number) => {
				// 		if (
				// 			!question ||
				// 			!values.homeUrls[index] ||
				// 			!values.homeDesc[index]
				// 		) {
				// 			console.log("index", index);
				// 			if (values.homeQuestions[index]) {
				// 				console.log(
				// 					`homeQuestions ${values.homeQuestions[index]} `,
				// 					values.homeQuestions[index]
				// 				);
				// 				values.homeQuestions.splice(index, 1);
				// 			}
				// 			if (values.homeUrls[index]) {
				// 				console.log(
				// 					`homeUrl ${values.homeUrls[index]} `,
				// 					values.homeUrls[index]
				// 				);
				// 				values.homeUrls.splice(index, 1);
				// 			}
				// 			if (values.homeDesc[index]) {
				// 				console.log(
				// 					`homeDesc ${values.homeUrls[index]} `,
				// 					values.homeUrls[index]
				// 				);
				// 				values.homeDesc.splice(index, 1);
				// 			}
				// 		} else {
				// 			additionalInfoHome.push({
				// 				homequestion: question as string,
				// 				homeUrl: values.homeUrls[index] as string,
				// 				homeDesc: values.homeDesc[index] as string,
				// 			});
				// 		}
				// 	}
				// );
				const filteredHomeQuestions = await values.homeQuestions
					.map((question: string, index: string | number) => ({
						question,
						url: values.homeUrls[index],
						desc: values.homeDesc[index],
					}))
					.filter(
						(item: {
							question: string;
							url: string;
							desc: string;
						}) => item.question && item.url && item.desc // Filter out invalid entries
					);

				filteredHomeQuestions.forEach(
					({
						question,
						url,
						desc,
					}: {
						question: string;
						url: string;
						desc: string;
					}) => {
						additionalInfoHome.push({
							homequestion: question,
							homeUrl: url,
							homeDesc: desc,
						});
					}
				);
				// console.log("additionalInfoHome", additionalInfoHome as any);
				// values.additionalInfoHome = addtionalInfo;
			}
			if (values?.similarQuestions?.length) {
				// await values.similarQuestions.map(
				// 	// eslint-disable-next-line array-callback-return
				// 	(question: string, index: number) => {
				// 		if (
				// 			!question ||
				// 			!values.similarUrls[index] ||
				// 			!values.similarDesc[index]
				// 		) {
				// 			if (values.similarQuestions[index])
				// 				values.similarQuestions.splice(index, 1);
				// 			if (values.similarUrls[index])
				// 				values.similarUrls.splice(index, 1);
				// 			if (values.similarDesc[index])
				// 				values.similarDesc.splice(index, 1);
				// 		} else {
				// 			additionalInfoSimilar.push({
				// 				similarquestion: question as string,
				// 				similarUrl: values.similarUrls[index] as string,
				// 				similarDesc: values.similarDesc[
				// 					index
				// 				] as string,
				// 			});
				// 		}
				// 	}
				// );
				const filteredSimilarQuestions = await values.similarQuestions
					.map((question: string, index: string | number) => ({
						question,
						url: values.similarUrls[index],
						desc: values.similarDesc[index],
					}))
					.filter(
						(item: {
							question: string;
							url: string;
							desc: string;
						}) => item.question && item.url && item.desc // Filter out invalid entries
					);

				filteredSimilarQuestions.forEach(
					({
						question,
						url,
						desc,
					}: {
						question: string;
						url: string;
						desc: string;
					}) => {
						additionalInfoSimilar.push({
							similarquestion: question,
							similarUrl: url,
							similarDesc: desc,
						});
					}
				);
				// console.log("additionalInfoSimilar", additionalInfoSimilar);
			}
		}
		if (!accessToken) return;
		// if (accessToken) return;

		const requestBody: TagCreateRequest = {
			title: values.title,
			type: values.type,
			vectorClass: values.vectorClass,
			thresholdShome: values.thresholdShome,
			thresholdExa: values.thresholdExa,
			rerank: checked,
			similarQuestions: values?.similarQuestions,
			similarUrls: values?.similarUrls,
			similarDesc: values?.similarDesc,
			homeQuestions: values?.homeQuestions,
			homeUrls: values?.homeUrls,
			homeDesc: values?.homeDesc,
			additionalInfoHome: additionalInfoHome,
			additionalInfoSimilar: additionalInfoSimilar,
		};
		if (tag) (requestBody as TagUpdateRequest).id = tag.id;
		console.log("requestBody", requestBody);
		// if (accessToken) return;
		try {
			setIsSubmitting(true);

			if (!tag) {
				const res = await createTag(requestBody);
				if (res) {
					handleClose();
				}
			} else {
				const res = await updateTag(requestBody as TagUpdateRequest);
				if (res) {
					handleClose();
				}
			}
		} catch (error) {
			// console.log("error setIsSubmitting", error);
		} finally {
			setIsSubmitting(false);
			actions.setSubmitting(false);
		}
	};

	return (
		<ModalTemplate
			openModal={modalName === TAG_MODAL}
			title={tag ? "Edit Tag" : "Add Tag"}
			dimension={ModalDimension.large}
		>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="left"
				justifyContent="center"
			>
				<Wrapper>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
						isSubmitting={isSubmitting}
						validateOnChange={true}
					>
						{(formik) => {
							return (
								<Form style={{ width: "100%" }}>
									<FormField
										name="title"
										label="Keyword"
										placeholder="Enter keyword"
									/>
									<Box
										sx={{
											marginBottom: "4px",
											minHeight: "90px",
										}}
									>
										<Typography>Select Type</Typography>
										<Select
											name="type"
											native
											label="Type"
											value={formik.values.type}
											onChange={formik.handleChange}
										>
											<option
												value={
													TagUpdateRequestTypeEnum.Keyword
												}
											>
												Keyword
											</option>
											<option
												value={
													TagUpdateRequestTypeEnum.Category
												}
											>
												Category
											</option>
											<option
												value={
													TagUpdateRequestTypeEnum.Tag
												}
											>
												Tag
											</option>
											<option
												value={
													TagUpdateRequestTypeEnum.Type
												}
											>
												Type
											</option>
										</Select>
									</Box>
									{formik.values.type ===
										TagUpdateRequestTypeEnum.Category && (
										<>
											<FormField
												name="vectorClass"
												label="Vector Class"
												placeholder="Enter vector class"
												disabled={tag ? true : false}
											/>

											<FormControlLabel
												control={
													<Checkbox
														checked={checked}
														onChange={handleChecked}
														aria-label="controlled"
														name="rerank"
													/>
												}
												label="Rerank"
												sx={{
													marginBottom: "4px",
													minHeight: "90px",
												}}
											/>
											<FormField
												name="thresholdShome"
												label="Threshold Shome"
												placeholder="Enter threshold shome"
											/>
											<FormField
												name="thresholdExa"
												label="Threshold Exa"
												placeholder="Enter threshold exa"
											/>
											{/* <FormField
												name="rerank"
												label="Rerank"
												type="checkbox"
												placeholder="Enter rerank"
											/> */}

											<FieldArray name="homeQuestions">
												{({ push, remove }) => (
													<Box>
														<Typography>
															Home Questions
														</Typography>
														{(
															formik.values
																.homeQuestions ??
															[]
														).map((_, index) => {
															return (
																<Box
																	key={index}
																	display="flex"
																	alignItems="center"
																	mb={3}
																>
																	<Box
																		sx={{
																			display:
																				"flex",
																			flexDirection:
																				"column",
																			width: "100%",
																			justifyContent:
																				"center",
																			alignItems:
																				"center",
																			gap: "6px",
																		}}
																	>
																		<TextField
																			name={`homeQuestions.${index}`}
																			label={`Q.${
																				index +
																				1
																			}`}
																			variant="outlined"
																			fullWidth
																			value={
																				formik
																					.values
																					.homeQuestions &&
																				formik
																					.values
																					.homeQuestions[
																					index
																				]
																			}
																			onChange={
																				formik.handleChange
																			}
																			error={
																				formik
																					.touched
																					.homeQuestions &&
																				Boolean(
																					formik
																						.errors
																						.homeQuestions?.[
																						index
																					]
																				)
																			}
																			helperText={
																				formik
																					.errors
																					.homeQuestions?.[
																					index
																				]
																			}
																			required={
																				true
																			}
																			autoComplete="off"
																		/>
																		<TextField
																			name={`homeUrls.${index}`}
																			label={`URL ${
																				index +
																				1
																			}`}
																			variant="outlined"
																			fullWidth
																			value={
																				formik
																					.values
																					.homeUrls &&
																				formik
																					.values
																					.homeUrls[
																					index
																				]
																			}
																			onChange={
																				formik.handleChange
																			}
																			error={
																				formik
																					.touched
																					.homeUrls &&
																				Boolean(
																					formik
																						.errors
																						.homeUrls?.[
																						index
																					]
																				)
																			}
																			helperText={
																				formik
																					.errors
																					.homeUrls?.[
																					index
																				]
																			}
																			required={
																				true
																			}
																			autoComplete="off"
																		/>
																		<TextField
																			name={`homeDesc.${index}`}
																			label={`Desc ${
																				index +
																				1
																			}`}
																			variant="outlined"
																			fullWidth
																			value={
																				formik
																					.values
																					.homeDesc &&
																				formik
																					.values
																					.homeDesc[
																					index
																				]
																			}
																			onChange={
																				formik.handleChange
																			}
																			error={
																				formik
																					.touched
																					.homeDesc &&
																				Boolean(
																					formik
																						.errors
																						.homeDesc?.[
																						index
																					]
																				)
																			}
																			helperText={
																				formik
																					.errors
																					.homeDesc?.[
																					index
																				]
																			}
																			required={
																				true
																			}
																			autoComplete="off"
																		/>
																		{/** add a hidden input for additionalInfoHome, which value would be additionalInfoHome[index]={homeUrl:homeUrls[index],homequestion:homeQuestions[index]}	 */}
																		{/* <TextField
																			name={`additionalInfoHome.${index}`}
																			label={`Additional Info ${
																				index +
																				1
																			}`}
																			type="hidden"
																			value={{
																				homeUrl:
																					formik
																						.values
																						.homeUrls[
																						index
																					] ??
																					"jemu",
																				homequestion:
																					formik
																						.values
																						.homeQuestions[
																						index
																					] ??
																					"jemu",
																				homeDesc:
																					formik
																						.values
																						.homeDesc[
																						index
																					] ??
																					"jemu",
																			}}
																		/> */}
																	</Box>
																	<IconButton
																		color="error"
																		onClick={() => {
																			remove(
																				index
																			);
																			formik.setFieldValue(
																				"homeUrls",
																				formik.values.homeUrls.filter(
																					(
																						_,
																						i
																					) =>
																						i !==
																						index
																				)
																			);
																			formik.setFieldValue(
																				"homeDesc",
																				formik.values.homeDesc.filter(
																					(
																						_,
																						i
																					) =>
																						i !==
																						index
																				)
																			);
																		}}
																		disabled={
																			(
																				formik
																					.values
																					.homeQuestions ??
																				[]
																			)
																				.length ===
																			1
																		}
																		aria-label="remove question"
																	>
																		<RemoveCircleOutlineIcon />
																	</IconButton>
																	{index ===
																		(formik
																			.values
																			.homeQuestions
																			?.length ??
																			0) -
																			1 && (
																		<IconButton
																			color="primary"
																			onClick={() =>
																				push(
																					""
																				)
																			}
																			disabled={
																				(
																					formik
																						.values
																						.homeQuestions ??
																					[]
																				)
																					.length >=
																				3
																			}
																			aria-label="add question"
																		>
																			<AddCircleOutlineIcon />
																		</IconButton>
																	)}
																</Box>
															);
														})}
													</Box>
												)}
											</FieldArray>
											<FieldArray name="similarQuestions">
												{({ push, remove }) => (
													<Box sx={{ mt: 6 }}>
														<Typography>
															Similar Questions
														</Typography>
														{(
															formik.values
																.similarQuestions ??
															[]
														).map((_, index) => (
															<Box
																key={index}
																display="flex"
																alignItems="center"
																mb={3}
															>
																<Box
																	sx={{
																		display:
																			"flex",
																		flexDirection:
																			"column",
																		width: "100%",
																		justifyContent:
																			"center",
																		alignItems:
																			"center",
																		gap: "6px",
																	}}
																>
																	<TextField
																		name={`similarQuestions.${index}`}
																		label={`Q.${
																			index +
																			1
																		}`}
																		variant="outlined"
																		fullWidth
																		value={
																			formik
																				.values
																				.similarQuestions &&
																			formik
																				.values
																				.similarQuestions[
																				index
																			]
																		}
																		onChange={
																			formik.handleChange
																		}
																		error={
																			formik
																				.touched
																				.similarQuestions &&
																			Boolean(
																				formik
																					.errors
																					.similarQuestions?.[
																					index
																				]
																			)
																		}
																		helperText={
																			formik
																				.errors
																				.similarQuestions?.[
																				index
																			]
																		}
																		required={
																			true
																		}
																	/>
																	<TextField
																		name={`similarUrls.${index}`}
																		label={`URL ${
																			index +
																			1
																		}`}
																		variant="outlined"
																		fullWidth
																		value={
																			formik
																				.values
																				.similarUrls &&
																			formik
																				.values
																				.similarUrls[
																				index
																			]
																		}
																		onChange={
																			formik.handleChange
																		}
																		error={
																			formik
																				.touched
																				.similarUrls &&
																			Boolean(
																				formik
																					.errors
																					.similarUrls?.[
																					index
																				]
																			)
																		}
																		helperText={
																			formik
																				.errors
																				.similarUrls?.[
																				index
																			]
																		}
																		required={
																			true
																		}
																	/>
																	<TextField
																		name={`similarDesc.${index}`}
																		label={`Desc ${
																			index +
																			1
																		}`}
																		variant="outlined"
																		fullWidth
																		value={
																			formik
																				.values
																				.similarDesc &&
																			formik
																				.values
																				.similarDesc[
																				index
																			]
																		}
																		onChange={
																			formik.handleChange
																		}
																		error={
																			formik
																				.touched
																				.similarDesc &&
																			Boolean(
																				formik
																					.errors
																					.similarDesc?.[
																					index
																				]
																			)
																		}
																		helperText={
																			formik
																				.errors
																				.similarDesc?.[
																				index
																			]
																		}
																		required={
																			true
																		}
																	/>
																	{/** add a hidden input for additionalInfoSimilar, which value would be additionalInfoSimilar[index]={similarUrl:similarUrls[index],similarquestion:similarQuestions[index]}	 */}
																	{/* <TextField
																		name={`additionalInfoSimilar.${index}`}
																		label={`Additional Info ${
																			index +
																			1
																		}`}
																		type="hidden"
																		value={{
																			similarUrl:
																				formik
																					.values
																					.similarUrls[
																					index
																				] ??
																				"jemu",
																			similarquestion:
																				formik
																					.values
																					.similarQuestions[
																					index
																				] ??
																				"jemu",
																			similarDesc:
																				formik
																					.values
																					.similarDesc[
																					index
																				] ??
																				"jemu",
																		}}
																		onChange={
																			formik.handleChange
																		}
																	/> */}
																</Box>
																<IconButton
																	color="error"
																	onClick={() => {
																		remove(
																			index
																		);
																		formik.setFieldValue(
																			"similarUrls",
																			formik.values.similarUrls.filter(
																				(
																					_,
																					i
																				) =>
																					i !==
																					index
																			)
																		);
																		formik.setFieldValue(
																			"similarDesc",
																			formik.values.similarDesc.filter(
																				(
																					_,
																					i
																				) =>
																					i !==
																					index
																			)
																		);
																	}}
																	disabled={
																		(
																			formik
																				.values
																				.similarQuestions ??
																			[]
																		)
																			.length ===
																		1
																	}
																	aria-label="remove question"
																>
																	<RemoveCircleOutlineIcon />
																</IconButton>
																{index ===
																	(formik
																		.values
																		.similarQuestions
																		?.length ??
																		0) -
																		1 && (
																	<IconButton
																		color="primary"
																		onClick={() =>
																			push(
																				""
																			)
																		}
																		aria-label="add question"
																	>
																		<AddCircleOutlineIcon />
																	</IconButton>
																)}
															</Box>
														))}
													</Box>
												)}
											</FieldArray>
										</>
									)}
									<Stack direction="row" spacing={2} mt={4}>
										<PrimaryButton
											text={tag ? "Save" : "Add New"}
											style={{ width: "100%" }}
											disabled={formik.isSubmitting}
											type="submit"
											icon={
												formik.isSubmitting ? (
													<CircularProgress
														size="1rem"
														color="inherit"
													/>
												) : null
											}
											onClick={() => {
												formik.handleSubmit();
												// console.log(
												// 	"formik",
												// 	formik.values
												// );
											}}
										/>
										<SecondaryOutlineButton
											style={{ width: "100%" }}
											text="Close"
											onClick={handleClose}
										/>
									</Stack>
								</Form>
							);
						}}
					</Formik>
				</Wrapper>
			</Box>
		</ModalTemplate>
	);
};

export default TagModal;

export const Wrapper = styled(Box)(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	background: "#fff",
	borderRadius: "5px",
	boxSizing: "border-box",
	flex: 1,
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		justifyContent: "flex-start",
	},
}));
