import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TagResponse, TagsResponse, TagUpdateRequest } from "../../../openapi";

export interface TagState {
  tags: Array<TagResponse> | undefined;
  pagination: TagsResponse | undefined;
  categoryPagination: TagsResponse | undefined;
  keywordPagination: TagsResponse | undefined;
  typesPagination: TagsResponse | undefined;
  tag: TagResponse | undefined;
  categories: Array<TagResponse> | undefined;
  keywords: Array<TagResponse> | undefined;
  types: Array<TagResponse> | undefined;
  allContentTypes: Array<string> | undefined;
  allTagsStrings: Array<string> | undefined;
  allCategoriesStrings: Array<string> | undefined;
  allKeywordsStrings: Array<string> | undefined;
  deleted: boolean;
}

const initialState: TagState = {
  tags: undefined,
  pagination: undefined,
  tag: undefined,
  deleted: false,
  categories: undefined,
  keywords: undefined,
  categoryPagination: undefined,
  keywordPagination: undefined,
  typesPagination: undefined,
  types: undefined,
  allContentTypes: undefined,
  allTagsStrings: undefined,
  allCategoriesStrings: undefined,
  allKeywordsStrings: undefined,
};

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    storeAllContentTypes: (state, action: PayloadAction<Array<string>>) => {
      state.allContentTypes = action.payload;
    },
    storeAllKeywordsStrings: (state, action: PayloadAction<Array<string>>) => {
      state.allKeywordsStrings = action.payload;
    },
    storeAllTagsStrings: (state, action: PayloadAction<Array<string>>) => {
      state.allTagsStrings = action.payload;
    },
    storeAllCategoriesStrings: (state, action: PayloadAction<Array<string>>) => {
      state.allCategoriesStrings = action.payload;
    },
    storeTags: (state, action: PayloadAction<Array<TagResponse>>) => {
      state.tags = action.payload;
    },
    storeCategories: (state, action: PayloadAction<Array<TagResponse>>) => {
      state.categories = action.payload;
    },
    storeKeywords: (state, action: PayloadAction<Array<TagResponse>>) => {
      state.keywords = action.payload;
    },
    storeTypes: (state, action: PayloadAction<Array<TagResponse>>) => {
      state.types = action.payload;
    },
    editTag: (state, action: PayloadAction<TagUpdateRequest | TagResponse>) => {
      state.tags =
        state.tags &&
        state.tags?.map((tag: TagResponse) =>
          tag.id === action.payload.id ? { ...tag, ...action.payload } : tag
        );
    },
    storeTagDetails: (state, action: PayloadAction<TagResponse>) => {
      state.tag = action.payload;
    },
    storePaginationDetails: (state, action: PayloadAction<TagsResponse>) => {
      state.pagination = action.payload;
    },
    storeCategoriesPaginationDetails: (state, action: PayloadAction<TagsResponse>) => {
      state.categoryPagination = action.payload;
    },
    storeKeywordsPaginationDetails: (state, action: PayloadAction<TagsResponse>) => {
      state.keywordPagination = action.payload;
    },
    storeTypesPaginationDetails: (state, action: PayloadAction<TagsResponse>) => {
      state.typesPagination = action.payload;
    },
    clearTagDetils: (state) => {
      state.tag = undefined;
    },
    storeDeleteStatus: (state, action: PayloadAction<boolean>) => {
      state.deleted = action.payload;
    },
  },
});

export const {
  storeTags,
  storePaginationDetails,
  storeTagDetails,
  editTag,
  clearTagDetils,
  storeDeleteStatus,
  storeCategories,
  storeKeywords,
  storeCategoriesPaginationDetails,
  storeKeywordsPaginationDetails,
  storeTypes,
  storeTypesPaginationDetails,
  storeAllContentTypes,
  storeAllTagsStrings,
  storeAllCategoriesStrings,
  storeAllKeywordsStrings,
} = tagsSlice.actions;

export default tagsSlice.reducer;
