/* tslint:disable */
/* eslint-disable */
/**
 * Main Service API
 * Main Service API
 *
 * The version of the OpenAPI document: 2.11.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface TagUpdateRequest
 */
export interface TagUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof TagUpdateRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TagUpdateRequest
     */
    'type'?: TagUpdateRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TagUpdateRequest
     */
    'vectorClass'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TagUpdateRequest
     */
    'similarQuestions'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TagUpdateRequest
     */
    'similarUrls'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TagUpdateRequest
     */
    'similarDesc'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TagUpdateRequest
     */
    'homeQuestions'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TagUpdateRequest
     */
    'homeUrls'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TagUpdateRequest
     */
    'homeDesc'?: Array<string>;
    /**
     * 
     * @type {Array<object>}
     * @memberof TagUpdateRequest
     */
    'additionalInfoHome'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof TagUpdateRequest
     */
    'additionalInfoSimilar'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof TagUpdateRequest
     */
    'thresholdShome'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagUpdateRequest
     */
    'thresholdExa'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TagUpdateRequest
     */
    'rerank'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TagUpdateRequest
     */
    'id': number;
}

export const TagUpdateRequestTypeEnum = {
    Keyword: 'Keyword',
    Category: 'Category',
    Tag: 'Tag',
    Topic: 'Topic',
    Type: 'Type',
    Others: 'Others'
} as const;

export type TagUpdateRequestTypeEnum = typeof TagUpdateRequestTypeEnum[keyof typeof TagUpdateRequestTypeEnum];


