import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box, Button, Grid, Link, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmptyListAnimation } from "../../animations/EmptyListAnimation";
import { LoadingAnimation } from "../../animations/LoadingAnimation";
import useChatHistory from "../../hooks/useChatHistory";
// import useFaq from "../../hooks/useFaq";
import { UserResponse } from "../../openapi";
import { ChatHistoryList } from "../../openapi/models/chat-history-list";
import { ChatHistoryResponse } from "../../openapi/models/chat-history-response";
import {
	saveSearchFromList,
	saveSearching,
	// storeHistoryDetails,
} from "../../redux/features/chatHistory/chatHistorySlice";
import { storeSearchUserEmail } from "../../redux/features/customers/cutomersSlice";
// import { saveModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { theme } from "../../theme";
// import { READ_MORE_DATASET } from "../../utils/constants";
import ReadMoreModal from "../modals/ReadMoreModal";
import QuestionIcon from "../svg-icon/QuestionIcon";
// import FeedbackIcon from "@mui/icons-material/Feedback";
// import EmailIcon from "@mui/icons-material/Email";
import RecommendIcon from "@mui/icons-material/Recommend";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
function formatHumanReadableDate(isoDate: string) {
	const date = new Date(isoDate);

	const options = {
		year: "numeric" as const,
		month: "long" as const,
		day: "numeric" as const,
		hour: "numeric" as const,
		minute: "numeric" as const,
		second: "numeric" as const,
		timeZoneName: "short" as const,
	};

	return date.toLocaleDateString("en-US", options);
}
const CustomerSearchListAndView = () => {
	const dispatch = useDispatch();
	const { getChatHistory, getThreadedHistory } = useChatHistory();
	// const { createFaq } = useFaq();
	const [customerEmail, setCustomerEmail] = useState<UserResponse>();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [totalResult, setTotalResult] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [historyList, setHistoryList] = useState<ChatHistoryResponse[]>([]);
	const {
		histories,
		isLoading,
		pagination,
		isSearched,
		searchFromList,
		threadPage,
	} = useSelector((state: RootState) => state.chatHistory);
	const { customers } = useSelector((state: RootState) => state.customer);
	// const { isFaqLoading, faqs } = useSelector((state: RootState) => state.faq);

	const handleUserHistory = (email: string) => {
		setLoading(true);
		dispatch(storeSearchUserEmail(email));
		dispatch(saveSearching(true));
		if (email && customers && customers.length) {
			const selectedUser = customers.filter(
				(data: UserResponse) => data.email === email
			);
			if (selectedUser && selectedUser.length > 0) {
				setCustomerEmail(selectedUser[0]);
				dispatch(saveSearchFromList(selectedUser[0]));

				getChatHistory(1, selectedUser[0].id.toString()).then(
					(r: unknown) => {
						const data = r as unknown as ChatHistoryList;
						// console.log(" history data ", data);
						setLoading(false);
						if (data.total) {
							setTotalResult(data.total);
						}
					}
				);
			}
		}
	};
	const handleUserThread = async (item: any) => {
		dispatch(saveSearching(true));
		setLoading(true);
		await getThreadedHistory(threadPage, item.userId, item.threadId).then(
			(r: unknown) => {
				const data = r as unknown as ChatHistoryList;
				setLoading(false);
				if (data.total) {
					setHistoryList(data.history.sort((a, b) => b.id - a.id));
					setTotalResult(data.total);
				}
			}
		);
	};
	// const handleReadMore = (data: any) => {
	// 	dispatch(storeHistoryDetails(data));
	// 	dispatch(saveModalName(READ_MORE_DATASET));
	// };

	// const handleAddFaq = async (data: ChatHistoryResponse) => {
	// 	if (data.id) {
	// 		await createFaq(data);
	// 	}
	// };
	///////NEED to update
	useEffect(() => {
		if (isSearched && searchFromList) {
			setCustomerEmail(searchFromList);
			getChatHistory(1, searchFromList.id.toString()).then(
				(r: unknown) => {
					const data = r as unknown as ChatHistoryList;
					if (data.total) {
						setTotalResult(data.total);
						//dispatch(clearSearchfromList());
					}
				}
			);
			// dispatch(saveSearching(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSearched, searchFromList]);
	useEffect(() => {
		setTotalResult(0);
		//getFaqList();
	}, []);
	useEffect(() => {
		if (historyList) console.log("historyList", historyList);
		if (histories) {
			// console.log(histories);
			setHistoryList(
				histories.length
					? histories.slice().sort((a, b) => b.id - a.id)
					: []
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [histories]);
	return (
		<Box id="scroller" sx={{ margin: "30px" }}>
			{customerEmail && isSearched && searchFromList && !isLoading && (
				<SearchUserWrapper>
					<Box>
						<Typography variant="h6">
							{customerEmail.firstName} {customerEmail.lastName}
						</Typography>
						<Typography variant="body1">
							{customerEmail.email}
						</Typography>
					</Box>
					{pagination && pagination.total > 0 && (
						<Box>
							<Typography
								variant="body1"
								sx={{ textDecoration: "underline" }}
							>
								{pagination.total} result found
							</Typography>
						</Box>
					)}
				</SearchUserWrapper>
			)}
			{isLoading || loading ? (
				<LoadingAnimation text="Loading..." />
			) : (
				<Box>
					<Grid container spacing={3}>
						{historyList &&
							historyList.length > 0 &&
							historyList.map(
								(item: ChatHistoryResponse, index) => {
									let refLink: string[] = [];
									let providedLinks = [] as unknown as [
										{
											link: string;
											recomended: boolean;
											isHelpful: boolean;
										}
									];
									if (
										item.searchResponse &&
										item.searchResponse.result &&
										item.searchResponse.result.length > 0 &&
										item.searchResponse.result[0].link
									) {
										item.searchResponse.result.map(
											// eslint-disable-next-line array-callback-return
											(item: any) => {
												refLink.push(item.link);
												providedLinks.push({
													link: item.link,
													recomended:
														item?.recomended,
													isHelpful: item?.isHelpful,
												});
											}
										);
									}
									return (
										<Grid item xs={6} key={index}>
											<Box
												key={item.id}
												p={2}
												borderRadius={"10px"}
												borderTop={`2px solid ${theme.palette.primary.main}`}
												bgcolor={"#F8F8F8"}
												minHeight={"440px"}
											>
												<Box>
													<Box mb={1}>
														<Box
															sx={{
																display: "flex",
																flexDirection:
																	"row",
																justifyContent:
																	"space-between",
															}}
														>
															<Typography
																fontWeight={
																	"600"
																}
																variant="subtitle1"
															>
																Question:
															</Typography>
															{/* <PrimaryButton
                              size="small"
                              text="Add to FAQ"
                              startIcon={<AddIcon />}
                              onClick={() => handleAddFaq(item)}
                              // disabled={isFaqLoading}
                            /> */}
														</Box>
														<Typography
															variant="subtitle1"
															maxHeight={"60px"}
															sx={{
																textWrap:
																	"nowrap",
																textOverflow:
																	"ellipsis",
																overflow:
																	"hidden",
															}}
														>
															{item.question}
														</Typography>
													</Box>
													<Box mb={1}>
														<Typography
															fontWeight={"600"}
															variant="subtitle1"
														>
															Short Description:
														</Typography>
														<Typography variant="subtitle1">
															{item.searchResponse.shortDescription.substring(
																0,
																150
															)}
															{item.searchResponse
																.shortDescription
																.length > 150 &&
																"..."}
														</Typography>
													</Box>
													<Box
														mb={1}
														sx={{
															overflow: "hidden",
														}}
													>
														<Typography
															fontWeight={"600"}
															variant="subtitle1"
														>
															Provided Links:
														</Typography>
														{providedLinks && (
															<Box>
																{providedLinks.map(
																	(
																		link,
																		index
																	) => {
																		return (
																			<Box
																				key={
																					index
																				}
																				display={
																					"flex"
																				}
																				flexDirection={
																					"row"
																				}
																				alignItems={
																					"center"
																				}
																				gap={
																					1
																				}
																			>
																				{link.recomended && (
																					<RecommendIcon fontSize="small" />
																				)}
																				{link.isHelpful ===
																					false && (
																					<ThumbDownIcon fontSize="small" />
																				)}
																				{link.isHelpful ===
																					true && (
																					<ThumbUpIcon fontSize="small" />
																				)}
																				<Link
																					href={
																						link.link
																					}
																					underline="none"
																					target="_blank"
																				>
																					<Typography
																						variant="subtitle1"
																						color={
																							theme
																								.palette
																								.primary
																								.main
																						}
																						sx={{
																							textWrap:
																								"nowrap",
																							textOverflow:
																								"ellipsis",
																							overflow:
																								"hidden",
																						}}
																					>
																						{
																							link.link
																						}
																					</Typography>
																				</Link>
																			</Box>
																		);
																	}
																)}
															</Box>
														)}
														{/* {refLink &&
														refLink.length > 0 &&
														refLink.map(
															(item: any, index2) => {
																return (
																	<Link
																		href={
																			item
																		}
																		underline="none"
																		target="_blank"
																		key={index2}
																	>
																		<Typography
																			variant="subtitle1"
																			color={
																				theme
																					.palette
																					.primary
																					.main
																			}
																			sx={{
																				textWrap:
																					"nowrap",
																				textOverflow:
																					"ellipsis",
																				overflow:
																					"hidden",
																			}}
																		>
																			{
																				item
																			}
																		</Typography>
																	</Link>
																);
															}
														)} */}
													</Box>
													<Box mb={1}>
														<Typography
															// fontWeight={"600"}
															variant="subtitle1"
														>
															<span
																style={{
																	fontWeight: 600,
																}}
															>
																Date:&nbsp;
															</span>
															{formatHumanReadableDate(
																item.createdAt.toString()
															)}
														</Typography>
														{/* <ReadMoreBtn
														startIcon={
															<FeedbackIcon />
														}
														sx={{
															cursor: "default",
														}}
													>
														Date: &nbsp;&nbsp;
														{formatHumanReadableDate(
															item.createdAt
														)}
													</ReadMoreBtn> */}
														{/* <Typography
														fontWeight={"600"}
														variant="subtitle1"
													>
														Keywords:
													</Typography> */}
														{/* <Typography
														variant="subtitle1"
														sx={{
															width: "80%",
															maxHeight: "200px",
															overflow: "hidden",
															textWrap: "nowrap",
															textOverflow:
																"ellipsis",
														}}
													>
														{item.searchResponse &&
                              item.searchResponse.result &&
                              item.searchResponse.result.length > 0 &&
                              item.searchResponse.result[0].keywords &&
                              item.searchResponse.result[0].keywords.length >
                                0 &&
                              item.searchResponse.result[0].keywords.join(", ")}
														{item.searchResponse &&
                              item.searchResponse.result &&
                              item.searchResponse.result.length > 0 &&
                              item.searchResponse.result.map((data: any) => {
                                return `${data.keywords.join(",")},`;
                              })}
													</Typography> */}
													</Box>
													{item.userComment && (
														<Box mb={1}>
															<Typography
																fontWeight={
																	"600"
																}
																variant="subtitle1"
															>
																User Comment:{" "}
																<span
																	style={{
																		color: "#000",
																		fontSize:
																			"14px",
																		fontWeight:
																			"400",
																	}}
																>
																	{
																		item.userComment
																	}
																</span>
															</Typography>
														</Box>
													)}
													{item.email && (
														// <ReadMoreBtn
														// 	startIcon={
														// 		<EmailIcon />
														// 	}
														// 	sx={{
														// 		cursor: "default",
														// 	}}
														// >
														// 	Email: &nbsp;&nbsp;
														// 	{item.email}
														// </ReadMoreBtn>
														<Box
															mt={{
																xs: "15px",
																xl: "10px",
															}}
															display={"flex"}
															flexDirection={
																"row"
															}
															alignItems={
																"center"
															}
															gap={1}
															justifyContent={
																"space-between"
															}
														>
															<Box>
																<Typography
																	// fontWeight={
																	// 	"600"
																	// }
																	variant="subtitle1"
																>
																	<span
																		style={{
																			fontWeight: 600,
																		}}
																	>
																		User
																		Email:
																	</span>
																	&nbsp;
																	{item.email}
																</Typography>
																{/* <Typography variant="subtitle1">
																{item.email}
															</Typography> */}
															</Box>

															{/* {!isSearched && !searchFromList && (
													  <PreviewAllHistoryBtn
													    startIcon={<VisibilityOutlinedIcon />}
													    onClick={() => handleUserHistory(item.email)}
													  >
													    View All History
													  </PreviewAllHistoryBtn>
													)} */}
														</Box>
													)}

													<Box
														mt={{
															xs: "15px",
															xl: "10px",
														}}
														display={"flex"}
														flexDirection={"row"}
														alignItems={"center"}
														gap={1}
														justifyContent={
															"flex-start"
														}
													>
														{!isSearched &&
															!searchFromList && (
																<>
																	<ReadMoreBtn
																		startIcon={
																			<VisibilityOutlinedIcon />
																		}
																		onClick={() =>
																			handleUserHistory(
																				item.email
																			)
																		}
																	>
																		User
																		Search
																		History
																	</ReadMoreBtn>
																	<ReadMoreBtn
																		startIcon={
																			<QuestionIcon />
																		}
																		onClick={() =>
																			handleUserThread(
																				item
																			)
																		}
																	>
																		Thread
																		Details
																	</ReadMoreBtn>
																</>
															)}

														{/* <ReadMoreBtn onClick={() => handleReadMore(item)}>
                            Read More
                          </ReadMoreBtn> */}
													</Box>
												</Box>
											</Box>
										</Grid>
									);
								}
							)}
					</Grid>
				</Box>
			)}

			{!isLoading && historyList && historyList.length === 0 && (
				<EmptyListAnimation title="No search history found" />
			)}

			<ReadMoreModal />
		</Box>
	);
};
export default CustomerSearchListAndView;
// const PreviewAllHistoryBtn = styled(Button)(() => ({
// 	textTransform: "capitalize",
// 	backgroundColor: "#E9E9E9",
// 	color: theme.palette.primary.main,
// 	fontSize: "16px",
// 	borderRadius: "12px",
// 	minWidth: "220px",
// 	marginTop: "10px",
// 	":hover": {
// 		backgroundColor: "#E9E9E9",
// 		color: theme.palette.primary.main,
// 	},
// }));
const ReadMoreBtn = styled(Button)(() => ({
	textTransform: "capitalize",
	backgroundColor: "#E9E9E9",
	color: "#000",
	fontSize: "16px",
	marginTop: "10px",
	borderRadius: "12px",
	minWidth: "150px",
	":hover": {
		backgroundColor: "#E9E9E9",
	},
}));

export const TableContainer = styled(Box)(() => ({
	display: "flex",
	flexDirection: "column",
	background: "#F8F8F8",
	width: "100%",
	maxWidth: "1400px",
	margin: "0 auto",
	height: "100%",
	transition: "0.3s all ease-in-out",
}));

export const TableView = styled(Box)(({ theme }) => ({
	position: "relative",
	width: "100%",
	border: "1px solid #E8E9EB",
	borderTopLeftRadius: "20px",
	borderTopRightRadius: "20px",
	borderBottomLeftRadius: "20px",
	borderBottomRightRadius: "20px",
	marginTop: "0",
	backgroundColor: "#fff",
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "stretch",
	transition: "0.3s all ease-in-out",
	overflow: "hidden",
	minHeight: "60vh",
	[theme.breakpoints.down("sm")]: {
		borderRadius: "14px",
	},
}));
export const SearchUserWrapper = styled(Box)(() => ({
	backgroundColor: "#F5F5F5",
	borderRadius: "20px",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	padding: "20px",
	marginBottom: "30px",
}));
